import HelloSignType from 'hellosign-embedded';
// @ts-expect-error DefinitelyTyped has types for this package, but TS seems to
// be confused by directly importing the minified production build
import HelloSign from 'hellosign-embedded/umd/embedded.production.min';
import { type IntlShape } from 'react-intl';
import {
  NODE_ENV,
  REACT_APP_GLOBAL_API_BASE_URL_V2,
  REACT_APP_MONOLITH_BASE_URL,
} from 'runtimeEnvVars';

import { APPLICANT_DRAWER_TABS } from 'containers/ApplicantDrawerPopup/constants';
import { addMessageAction } from 'containers/FlashMessage/actions';
import {
  openApplicantDrawerPopup,
  openEditPartnerOptionsDataModal,
  openImmediateHiringDecisionModal,
  openReviewAssessmentModal,
  openScheduleApplicantModal,
  openSendEmailPopup,
  openVideoRecordingPopover,
} from 'containers/GlobalPopup/actions';
import { TEMPLATE_TYPES } from 'containers/SendEmailPopup/constants';
import {
  CATEGORY_SHOWS_STAGE_TYPE,
  LABEL_STATUSES,
  MENU_OPTION_TYPES,
  OPEN_EMAIL_POPUP,
  OPEN_HELLOSIGN_IFRAME,
  STATUS_LABEL_STATUS,
  STATUS_TYPES,
  StatusCode,
} from 'containers/StatusLabels/constants';
import messages from 'containers/StatusLabels/messages';
import { apiGet, apiPatch } from 'utils/axios';

import type { StatusLabelProps } from '@fountain/fountain-ui-components';
import type { Dispatch } from 'redux';

export interface AssessmentFormDataEntry {
  [key: string]: {
    options: Array<{
      value: string;
      visible: boolean;
      correct_answer?: boolean; // eslint-disable-line camelcase
      weight?: number;
    }>;
    question: string;
    applicant_answer?: string; // eslint-disable-line camelcase
    position: number;
  };
}

export interface AssessmentFormData {
  id: string;
  applicant_name: string; // eslint-disable-line camelcase
  completed_at: string; // eslint-disable-line camelcase
  score: string;
  response_data: AssessmentFormDataEntry; // eslint-disable-line camelcase
  title: string;
}

interface VideoRecording {
  question: string;
  video_url: string; // eslint-disable-line camelcase
}

interface SignatureRequests {
  title: string;
  url: string;
}

export interface Action {
  assessment_data?: AssessmentFormData; // eslint-disable-line camelcase
  enterprise_url?: boolean; // eslint-disable-line camelcase
  http_verb?: string; // eslint-disable-line camelcase
  i9_form_id?: string; // eslint-disable-line camelcase
  label: string;
  partner_details?: unknown; // eslint-disable-line camelcase
  partner_status_id?: string; // eslint-disable-line camelcase
  type: string;
  url?: string;
  video_recordings?: VideoRecording[]; // eslint-disable-line camelcase
  template_type?: string; // eslint-disable-line camelcase
  signature_id?: string; // eslint-disable-line camelcase
  signature_requests?: SignatureRequests[]; // eslint-disable-line camelcase
  data?: Record<string, unknown>;
  partner_id?: string; // eslint-disable-line camelcase
  partner_stage_id?: string; // eslint-disable-line camelcase
  partner_option_id?: string; // eslint-disable-line camelcase
}

export interface Status {
  status_id: string; // eslint-disable-line camelcase
  category: string;
  external_id: string; // eslint-disable-line camelcase
  fullLabel?: string; // TODO: square up `fullLabel` with backend property naming in TSQ-517
  is_partner?: boolean; // eslint-disable-line camelcase
  is_partner_label?: boolean; // eslint-disable-line camelcase
  score?: number;
  stage_id?: number; // eslint-disable-line camelcase
  status: string;
  type: string;
  type_code?: StatusCode; // eslint-disable-line camelcase
  title: string;
  priority: string;
  actions: Action[];
  additional_info: string[]; // eslint-disable-line camelcase
  tooltip?: string;
  stage_type?: string; // eslint-disable-line camelcase
}

interface PartnerLabel {
  category: string;
  external_id: string; // eslint-disable-line camelcase
  is_partner: true; // eslint-disable-line camelcase
  is_partner_label: true; // eslint-disable-line camelcase
  status: string;
  type: string;
  type_code: StatusCode; // eslint-disable-line camelcase
  title: string;
}

export type StatusLabelStatus =
  | 'action'
  | 'incomplete'
  | 'in-progress'
  | 'partner'
  | 'success'
  | 'warning';

export type StatusType =
  | 'incomplete'
  | 'waiting'
  | 'in_progress'
  | 'pending_action'
  | 'completed'
  | 'error'
  | 'opted_out'
  | 'warning'
  | 'advance'
  | 'reject';

export const getStatusLabelStatus = (
  statusType: StatusType,
): StatusLabelStatus => {
  switch (statusType) {
    case STATUS_TYPES.INCOMPLETE:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.INCOMPLETE;

    case STATUS_TYPES.IN_PROGRESS:
    case STATUS_TYPES.WAITING:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.IN_PROGRESS;

    case STATUS_TYPES.PENDING_ACTION:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.ACTION;

    case STATUS_TYPES.COMPLETED:
    case STATUS_TYPES.ADVANCE:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.SUCCESS;

    case STATUS_TYPES.ERROR:
    case STATUS_TYPES.WARNING:
    case STATUS_TYPES.REJECT:
    case STATUS_TYPES.OPTED_OUT:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.WARNING;

    case STATUS_TYPES.PARTNER:
      // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
      return STATUS_LABEL_STATUS.PARTNER;

    default:
      throw new Error(`Unknown status type: ${statusType}`);
  }
};

const isPartnerLabel = (
  status: Status | PartnerLabel,
): status is PartnerLabel => (status as PartnerLabel).is_partner_label;

const hasActions = (status: Status): boolean => Boolean(status.actions.length);

const hasVideoRecordings = (action: Action) => {
  return action.video_recordings && action.video_recordings.length > 0;
};

const isFileReview = (status: Status | PartnerLabel): boolean => {
  return status.type_code === 'file_review';
};

export const handleNoPopupOption = (
  status: Status | PartnerLabel,
  dispatch: Dispatch,
) => {
  if (isPartnerLabel(status)) {
    // partner labels do not have an actions property on them. we may need to pass an empty array as the actions in the case of partner labels to avoid undefined errors
    return () => {};
  }
  if (hasActions(status)) {
    const applicantId = status.external_id;
    const action = status.actions[0];
    const { title: statusLabelTitle } = status;
    if (hasVideoRecordings(action)) {
      return () =>
        dispatch(openVideoRecordingPopover(applicantId, statusLabelTitle));
    }
    if (isFileReview(status)) {
      return () =>
        dispatch(
          openApplicantDrawerPopup(applicantId, APPLICANT_DRAWER_TABS.FILES),
        );
    }
  }

  // For `consistent-return`—just no-op for safety, as with partner labels?
  return () => {};
};

const isValidLink = (action: Action): boolean =>
  Boolean(action.url) && typeof action.url === 'string';

const openUrlNewTab = (url: string) => {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  }
};

const handleLinkAction = (
  status: Status,
  action: Action,
  dispatch: Dispatch,
  intl: IntlShape,
) => {
  if (isValidLink(action)) {
    if (action.enterprise_url) {
      const url = REACT_APP_MONOLITH_BASE_URL + (action.url ?? '');
      return openUrlNewTab(url);
    }
    // @ts-expect-error Should probably handle the case when `url` is indeed `undefined`
    return openUrlNewTab(action.url);
  }

  return dispatch(
    addMessageAction(
      intl.formatMessage(messages.noLink, { label: action.label }),
      'error',
    ),
  );
};

const openScoreCardPopup = (url: string) => {
  const win = window.open(
    url,
    'Score Card',
    'height=340,width=600,top=0,left=0',
  );
  if (win) {
    win.focus();
  }
};

const shouldOpenEmailPopup = (action: Action): boolean => {
  // @ts-expect-error Need to narrow out `undefined` from `template_type` or provide a default
  return Boolean(OPEN_EMAIL_POPUP[action.template_type]);
};

// TODO: This should probably be narrowed into per-email template params types
type SendEmailPopupParams = {
  partnerStatusId?: string;
  i9FormId?: string;
};

const getSendEmailPopupParams = (action: Action): SendEmailPopupParams => {
  const params: SendEmailPopupParams = {};
  if (action.template_type === TEMPLATE_TYPES.REQUEST_REVISION) {
    params.partnerStatusId = action.partner_status_id;
  }

  if (action.i9_form_id) {
    params.i9FormId = action.i9_form_id;
  }
  return params;
};

const isAssessment = (status: Status): boolean => {
  return status.type_code === 'assessment';
};

const isScheduler = (status: Status): boolean => {
  return status.type_code === 'sessions';
};

const firstTimeScheduling = (status: Status): boolean => {
  return isScheduler(status) && status.status_id !== 'not-scheduled';
};

const isMarkingAttendance = (action: Action): boolean => {
  return action.data?.attended === true || action.data?.attended === false;
};

interface EnvVars {
  REACT_APP_HELLOSIGN_CLIENT_ID?: string;
}

const shouldOpenHelloSignPopup = (status: Status): boolean => {
  return Boolean(OPEN_HELLOSIGN_IFRAME[status.type_code ?? status.type]);
};

const handlePopupAction = (
  status: Status,
  action: Action,
  dispatch: Dispatch,
  envVars: EnvVars,
  fetchApplicantDetail: () => void,
  intl: IntlShape,
  handleOpenPartnerDetailsModal: (arg: unknown) => void,
  immediateHiringDecision: boolean,
) => {
  const applicantId = status.external_id;

  // the only time a link gets this far is when its the view and edit option from scorecard status labels
  if (isValidLink(action)) {
    // @ts-expect-error Should probably handle the case when `url` is indeed `undefined`
    return openScoreCardPopup(action.url);
  }
  if (shouldOpenEmailPopup(action)) {
    const params = getSendEmailPopupParams(action);
    return dispatch(
      openSendEmailPopup(applicantId, action.template_type, params),
    );
  }
  if (hasVideoRecordings(action)) {
    return dispatch(openVideoRecordingPopover(applicantId, status.title));
  }
  if (isFileReview(status)) {
    return dispatch(
      openApplicantDrawerPopup(applicantId, APPLICANT_DRAWER_TABS.FILES),
    );
  }
  if (isAssessment(status) && action.assessment_data !== undefined) {
    return dispatch(
      openReviewAssessmentModal({
        applicantId,
        assessmentFormId: action.assessment_data.id,
      }),
    );
  }
  if (isScheduler(status)) {
    if (immediateHiringDecision && !firstTimeScheduling) {
      return dispatch(
        openImmediateHiringDecisionModal({
          applicantId,
          schedulerStageId: status.stage_id,
          schedulerStageType: status.stage_type ?? '',
          attended: action.data?.attended === true,
        }),
      );
    }
    return dispatch(
      openScheduleApplicantModal({
        applicantId,
        schedulerStageId: status.stage_id,
        schedulerStageType: status.stage_type ?? '',
      }),
    );
  }
  if (shouldOpenHelloSignPopup(status)) {
    return apiGet(
      /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
      `${REACT_APP_GLOBAL_API_BASE_URL_V2}/applicants/${applicantId}/sign_url/${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        action.signature_id
      }`,
    ).then(
      (res: {
        data: {
          hs_client_id?: string; // eslint-disable-line camelcase
          sign_url: string; // eslint-disable-line camelcase
        };
      }) => {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
        const client: HelloSignType = new HelloSign({
          clientId:
            res.data.hs_client_id ?? envVars.REACT_APP_HELLOSIGN_CLIENT_ID,
        });

        client.open(res.data.sign_url, {
          testMode: NODE_ENV === 'development' || NODE_ENV === 'staging',
        });

        return client.on('sign', () => {
          void apiPatch(
            /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
            `${REACT_APP_GLOBAL_API_BASE_URL_V2}/users/update_user_signature/${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              action.signature_id
            }`,
            { event: 'signature_request_signed' },
          ).then(r => {
            if (r.status === 200) {
              fetchApplicantDetail();
            }
          });
        });
      },
    );
  }
  if (action.partner_details) {
    return handleOpenPartnerDetailsModal(action.partner_details);
  }
  if (action.partner_stage_id && action.partner_id) {
    return dispatch(
      openEditPartnerOptionsDataModal({
        applicantId,
        partnerStageId: action.partner_stage_id,
        partnerId: action.partner_id,
        partnerOptionId: action.partner_option_id,
        partnerStatusId: action.partner_status_id,
      }),
    );
  }

  return dispatch(
    addMessageAction(
      intl.formatMessage(messages.noLink, { label: action.label }),
      'error',
    ),
  );
};

interface HandleHttpActionValue {
  error: Error;
  handleHttpAction: (
    status: Status,
    action: Action,
    fetchApplicantDetail: () => void,
    callback?: () => void,
  ) => void;
  isMakingHttpRequest: boolean;
}

export interface ActionDependencies {
  handleHttpAction: HandleHttpActionValue;
  dispatch: Dispatch;
  envVars: EnvVars;
  fetchApplicantDetail: () => void;
  intl: IntlShape;
  handleOpenPartnerDetailsModal: (arg: unknown) => void;
  setIsModalOpen: (isOpen: boolean) => void;
}

/**
 * Create action callbacks for a given status
 */
export const getStatusLabelActionCallback = (
  status: Status,
  action: Action,
  {
    dispatch,
    envVars,
    fetchApplicantDetail,
    intl,
    handleHttpAction: { handleHttpAction },
    handleOpenPartnerDetailsModal,
    setIsModalOpen,
  }: ActionDependencies,
  immediateHiringDecision?: boolean,
) => {
  switch (action.type) {
    case MENU_OPTION_TYPES.HTTP: {
      if (
        immediateHiringDecision &&
        isScheduler(status) &&
        isMarkingAttendance(action)
      ) {
        return () =>
          handleHttpAction(status, action, fetchApplicantDetail, () => {
            return dispatch(
              openImmediateHiringDecisionModal({
                applicantId: status.external_id,
                schedulerStageId: status.stage_id,
                schedulerStageType: status.stage_type ?? '',
                attended: action.data?.attended === true,
              }),
            );
          });
      }
      return () => handleHttpAction(status, action, fetchApplicantDetail);
    }
    case MENU_OPTION_TYPES.LINK: {
      return () => handleLinkAction(status, action, dispatch, intl);
    }
    case MENU_OPTION_TYPES.POPUP: {
      return () =>
        void handlePopupAction(
          status,
          action,
          dispatch,
          envVars,
          fetchApplicantDetail,
          intl,
          handleOpenPartnerDetailsModal,
          !!immediateHiringDecision,
        );
    }
    case MENU_OPTION_TYPES.MODAL: {
      return () => void setIsModalOpen(true);
    }
    default: {
      return () =>
        addMessageAction(`Unknown status type ${action.type}`, 'error');
    }
  }
};

/**
 * Maps over the list of actions from our back end and returns a list of actions appropriate for the new status labels  -> {label: 'foo', onClick: () => {}}
 */
export const getActions = (
  status: Status,
  actionDependencies: ActionDependencies,
  immediateHiringDecision?: boolean,
) => {
  if (!status.actions) return [];
  return status.actions.map(action => ({
    label: action.label,
    onClick: getStatusLabelActionCallback(
      status,
      action,
      actionDependencies,
      immediateHiringDecision,
    ),
    data: action.data,
  }));
};

/**
 * Indicates if the status should be a clickable or menu based
 */
export const buildLabelTitle = (
  status: Status,
): { priorityText: string; primaryText: string } => {
  let labelText =
    CATEGORY_SHOWS_STAGE_TYPE[status.category] || status.is_partner
      ? `[${status.type}] ${status.title}`
      : status.title;
  if (
    (status.type_code === 'file_review' ||
      status.type_code === 'video_recording') &&
    status.actions.length > 0
  ) {
    labelText = status.actions[0].label;
  }
  if (status.score) {
    labelText =
      Number(status.score) > 0
        ? `${status.type} (${status.score})`
        : `${status.type}`;
  }
  return { primaryText: labelText, priorityText: status.priority };
};

export const statusToStatusLabelStatus = (
  status: Status,
): StatusLabelStatus => {
  if (isPartnerLabel(status)) {
    // @ts-expect-error This won't be a `string` when the StatusLabelStatus enum is available…
    return STATUS_LABEL_STATUS.PARTNER;
  }

  // @ts-expect-error Assuming Status['status'] is of type LabelStatus until
  // `LABEL_STATUSES` becomes an `enum`
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const statusType: StatusType | undefined = LABEL_STATUSES[status.status_id];

  if (!statusType) {
    window.console.error(
      'Found a status label without an id, status id:',
      status.status_id,
      'Status object: ',
      JSON.stringify(status, undefined, 2),
    );
  }

  // @ts-expect-error we're actualy reliant on the `throw` that got turned into
  // the `console.error` above. It should be switched back to a `throw` or
  // better yet an `invariant`! Not only do we need it for type narrowing,
  // we'll never see the log and a customer won't report it…
  return getStatusLabelStatus(statusType);
};

const isStatusLabelClick = (status: Status): boolean => {
  return (
    Boolean(status.type) &&
    status.type_code === 'file_review' &&
    status.status_id === 'pending-action'
  );
};

export const statusToStatusLabelProps = (
  status: Status,
  actionDependencies: ActionDependencies,
  immediateHiringDecision?: boolean,
): StatusLabelProps & { key: React.Key } => ({
  actions: getActions(status, actionDependencies, immediateHiringDecision),
  label: buildLabelTitle(status),
  status: statusToStatusLabelStatus(status),
  options: {
    // TODO: square up `fullLabel` with backend property naming in TSQ-517
    ...(status.fullLabel ? { fullLabel: status.fullLabel } : null),
    ...(status.tooltip || status.additional_info
      ? { additionalInfo: status.additional_info ?? [status.tooltip] }
      : null),
    ...(isStatusLabelClick(status) ? { isClick: true } : null),
  },
  key: `${status.title}${status.status}${status.category}${status.external_id}`,
});
