import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  and: {
    defaultMessage: 'AND',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.and`,
  },
  applicantDuplicateStatus: {
    defaultMessage: 'Applicant duplicate status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantDuplicateStatus`,
  },
  applicantFieldComparison: {
    defaultMessage: 'Applicant field comparison',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantFieldComparison`,
  },
  applicantFieldDoesntExist: {
    defaultMessage: "Applicant field doesn't exist",
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantFieldDoesntExist`,
  },
  applicantFieldIsDuplicate: {
    defaultMessage: 'Applicant is duplicate',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantFieldIsDuplicate`,
  },
  applicantFieldExists: {
    defaultMessage: 'Applicant field exists',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantFieldExists`,
  },
  applicantFieldIs: {
    defaultMessage: 'Applicant field is',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantFieldIs`,
  },
  applicantAgeIs: {
    defaultMessage: 'Applicant age is',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantAgeIs`,
  },
  applicantLabelIs: {
    defaultMessage: 'Applicant label is',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantLabelIs`,
  },
  applicantLabelIsNot: {
    defaultMessage: 'Applicant label is not',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantLabelIsNot`,
  },
  applicantLocation: {
    defaultMessage: 'Applicant location',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.applicantLocation`,
  },
  assessmentFormScore: {
    defaultMessage: 'Assessment form score',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.assessmentFormScore`,
  },
  awaitingAuthorizedRepresentative: {
    defaultMessage: 'Awaiting authorized representative',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.awaitingAuthorizedRepresentative`,
  },
  awaitingEmployee: {
    defaultMessage: '{formType}: Not Started',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.awaitingEmployee`,
  },
  awaitingEmployer: {
    defaultMessage: '{formType}: Required Action: Employer',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.awaitingEmployer`,
  },
  backgroundCheck: {
    defaultMessage: 'Background Check',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.backgroundCheck`,
  },
  backgroundCheckStatus: {
    defaultMessage: 'Background Check status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.backgroundCheckStatus`,
  },
  canceled: {
    defaultMessage: 'Canceled',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.canceled`,
  },
  cantBeBlank: {
    defaultMessage: "can't be blank",
    id: `app.containers.WorkflowEditor.components.Rules.Condition.cantBeBlank`,
  },
  caseStatus: {
    defaultMessage: 'Case Status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.caseStatus`,
  },
  clear: {
    defaultMessage: 'Clear',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.clear`,
  },
  closeCaseAndResubmit: {
    defaultMessage: 'Close case and resubmit',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.closeCaseAndResubmit`,
  },
  closed: {
    defaultMessage: 'Closed',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.closed`,
  },
  completed: {
    defaultMessage: 'Completed',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.completed`,
  },
  condition: {
    defaultMessage: 'CONDITION',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.condition`,
  },
  conditionType: {
    defaultMessage: 'Condition Type',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.conditionType`,
  },
  consented: {
    defaultMessage: 'Consented',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.consented`,
  },
  consider: {
    defaultMessage: 'Consider',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.consider`,
  },
  considerEngaged: {
    defaultMessage: 'Consider (Engaged)',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.considerEngaged`,
  },
  customerAttribute: {
    defaultMessage: 'Customer attribute',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.customerAttribute`,
  },
  deleteConditionNumber: {
    defaultMessage: 'Delete condition {number}',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.deleteConditionNumber`,
  },
  dispute: {
    defaultMessage: 'Dispute',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.dispute`,
  },
  documentIsUploaded: {
    defaultMessage: 'Document is uploaded',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.documentIsUploaded`,
  },
  documentSigningStatus: {
    defaultMessage: 'Document signing status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.documentSigningStatus`,
  },
  docuSignDocument: {
    defaultMessage: 'DocuSign Document',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.docuSignDocument`,
  },
  doesNotEqual: {
    defaultMessage: 'Does not equal',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.doesNotEqual`,
  },
  doesNotInclude: {
    defaultMessage: 'Does not include',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.doesNotInclude`,
  },
  draft: {
    defaultMessage: 'Draft',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.draft`,
  },
  employmentAuthorized: {
    defaultMessage: 'Employment Authorized',
    id: 'app.containers.WorkflowEditor.components.Rules.Condition.employmentAuthorized',
  },
  engaged: {
    defaultMessage: 'Engaged',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.engaged`,
  },
  equals: {
    defaultMessage: 'Equals',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.equals`,
  },
  everifyCaseStatus: {
    defaultMessage: 'Everify case status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.everifyCaseStatus`,
  },
  everifyCaseEligibilityStatement: {
    defaultMessage: 'Everify case eligibility statement',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.everifyCaseEligibilityStatement`,
  },
  finalNonConfirmation: {
    defaultMessage: 'Final non-confirmation',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.finalNonConfirmation`,
  },
  finalConfirmation: {
    defaultMessage: 'Final confirmation',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.finalConfirmation`,
  },
  formCompleted: {
    defaultMessage: '{formType}: Completed',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.formCompleted`,
  },
  formPending: {
    defaultMessage: '{formType}: Pending Re-submission',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.formPending`,
  },
  greaterThan: {
    defaultMessage: 'Greater than',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.greaterThan`,
  },
  greaterThanOrEqual: {
    defaultMessage: 'Greater than or equal',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.greaterThanOrEqual`,
  },
  helloSignDocument: {
    defaultMessage: 'HelloSign Document',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.helloSignDocument`,
  },
  i9: {
    defaultMessage: 'I-9',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.i9`,
  },
  i9FormStatus: {
    defaultMessage: 'I-9 form status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.i9FormStatus`,
  },
  includes: {
    defaultMessage: 'Includes',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.includes`,
  },
  isDuplicate: {
    defaultMessage: 'Is Duplicate',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.isDuplicate`,
  },
  isGreaterThan: {
    defaultMessage: 'is greater than',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.isGreaterThan`,
  },
  isInTheList: {
    defaultMessage: 'Is in the list',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.isInTheList`,
  },
  isNotDuplicate: {
    defaultMessage: 'Is Not Duplicate',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.isNotDuplicate`,
  },
  label: {
    defaultMessage: 'Label',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.label`,
  },
  lessonlyScore: {
    defaultMessage: 'Lessonly score',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.lessonlyScore`,
  },
  lessonlyStatus: {
    defaultMessage: 'Lessonly status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.lessonlyStatus`,
  },
  lessThan: {
    defaultMessage: 'Less than',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.lessThan`,
  },
  lessThanOrEqual: {
    defaultMessage: 'Less than or equal',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.lessThanOrEqual`,
  },
  manualReview: {
    defaultMessage: 'Manual review',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.manualReview`,
  },
  needsReview: {
    defaultMessage: 'Needs review',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.needsReview`,
  },
  noActionFnc: {
    defaultMessage: 'No Action FNC',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.noActionFnc`,
  },
  noShow: {
    defaultMessage: 'No Show',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.noShow`,
  },
  northpassCourseProgress: {
    defaultMessage: 'Northpass course progress',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.northpassCourseProgress`,
  },
  northpassQuizScore: {
    defaultMessage: 'Northpass quiz score',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.northpassQuizScore`,
  },
  notAuthorizedEmployment: {
    defaultMessage: 'Not Authorized Employment',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.notAuthorizedEmployment`,
  },
  notCompleted: {
    defaultMessage: 'Not completed',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.notCompleted`,
  },
  notConsented: {
    defaultMessage: 'Not consented',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.notConsented`,
  },
  optedOut: {
    defaultMessage: '{formType}: Opted Out',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.optedOut`,
  },
  or: {
    defaultMessage: 'OR',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.or`,
  },
  package: {
    defaultMessage: 'Package',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.package`,
  },
  partnerDetailHasKey: {
    defaultMessage: 'Partner detail has key',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerDetailHasKey`,
  },
  partnerDetailHasKeyWithValue: {
    defaultMessage: 'Partner detail has key with value',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerDetailHasKeyWithValue`,
  },
  partnerLabelDoesNotHaveKey: {
    defaultMessage: 'Partner label does not have key',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerLabelDoesNotHaveKey`,
  },
  partnerLabelHasKey: {
    defaultMessage: 'Partner label has key',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerLabelHasKey`,
  },
  partnerLabelHasKeyWithValue: {
    defaultMessage: 'Partner label has key with value',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerLabelHasKeyWithValue`,
  },
  partnerStatusHasTitle: {
    defaultMessage: 'Partner status has title',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerStatusHasTitle`,
  },
  partnerStatusIs: {
    defaultMessage: 'Partner status is',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerStatusIs`,
  },
  partnerStatusIsNot: {
    defaultMessage: 'Partner status is not',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.partnerStatusIsNot`,
  },
  pending: {
    defaultMessage: 'Pending',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.pending`,
  },
  photoMatch: {
    defaultMessage: 'Photo match',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.photoMatch`,
  },
  pendingReferral: {
    defaultMessage: 'Pending referral',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.pendingReferral`,
  },
  postAdverseAction: {
    defaultMessage: 'Consider (Post-Adverse)',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.postAdverseAction`,
  },
  queued: {
    defaultMessage: 'Queued',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.queued`,
  },
  referred: {
    defaultMessage: 'Referred',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.referred`,
  },
  resubmitCase: {
    defaultMessage: 'Resubmit Case',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.resubmitCase`,
  },
  running: {
    defaultMessage: 'Running',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.running`,
  },
  scanAndUpload: {
    defaultMessage: 'Scan and upload',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.scanAndUpload`,
  },
  scoreCardIs: {
    defaultMessage: 'Score Card is',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.scoreCardIs`,
  },
  stage: {
    defaultMessage: 'Stage',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.stage`,
  },
  status: {
    defaultMessage: 'Status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.status`,
  },
  sterlingServiceLevelResultsInclude: {
    defaultMessage: 'Sterling service-level results include',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.sterlingServiceLevelResultsInclude`,
  },
  suspended: {
    defaultMessage: 'Suspended',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.suspended`,
  },
  techCheckStatus: {
    defaultMessage: 'Tech Check status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.techCheckStatus`,
  },
  unconfirmedData: {
    defaultMessage: 'Unconfirmed data',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.unconfirmedData`,
  },
  w4Fed: {
    defaultMessage: 'W4 Fed',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.w4Fed`,
  },
  w4FederalFormStatus: {
    defaultMessage: 'W4 Federal form status',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.w4FederalFormStatus`,
  },
});
