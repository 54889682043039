import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import ApplicantAddress from 'components/ApplicantAddress';
import LicenseDataContainer from 'components/LicenseDataContainer';
import WorkAvailabilityInput from 'components/WorkAvailabilityInput';
import globalMessages from 'shared/global/messages';

import { DETAIL_TYPES } from '../../../constants';
import { DetailType, DetailValueType } from '../../../props';
import useStyles from '../styles';
import { ValueTypeEnum } from './constants';
import { getType } from './utils';

const getLabelForValue = (options, value) => {
  const currentOption = options.find(option => option.value === value);
  return currentOption ? currentOption.label : value;
};

const Values = ({ detail, value }) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const valueType = getType(value);
  const formatStringToFloat = input =>
    parseFloat(input, 2).toLocaleString('en-US');

  if (detail.type) {
    switch (detail.type) {
      case DETAIL_TYPES.address:
        return (
          <ApplicantAddress
            address={value}
            onAddressChange={() => {}}
            handleKeyPress={() => {}}
            editDisabled
          />
        );
      case DETAIL_TYPES.availability:
        return (
          <WorkAvailabilityInput
            selectedOptions={value}
            onChange={() => {}}
            editDisabled
          />
        );
      case DETAIL_TYPES.text_field:
        if (detail.key === 'hiring_manager_confirmed_pay_rate') {
          return `$${formatStringToFloat(value)}`;
        }
        break;
      case DETAIL_TYPES.date_picker:
        return (
          value && (
            <FormattedDate dateStyle="short" value={value} timeZone="UTC" />
          )
        );
      case DETAIL_TYPES.license_data:
        return (
          <LicenseDataContainer
            licenseData={value}
            editDisabled
            handleKeyPress={() => {}}
            setValue={() => {}}
          />
        );
      default:
        break;
    }
  } else {
    switch (valueType) {
      case ValueTypeEnum.ARRAY:
      case ValueTypeEnum.OBJECT:
        return Object.keys(value).map(key => (
          <>
            <Typography className={styles.title} variant="h4">
              {key}
            </Typography>
            <Values detail={detail} value={value[key]} />
          </>
        ));
      case ValueTypeEnum.NUMBER:
      case ValueTypeEnum.STRING:
        return value;
      default:
        break;
    }
  }

  if (valueType === ValueTypeEnum.BOOLEAN) {
    const messageKey = value ? 'yes' : 'no';
    return formatMessage(globalMessages[messageKey]);
  }

  if (value && detail.option_bank_id) {
    return value.label || value;
  }

  if (detail.options && detail.options.length > 0) {
    if (valueType === ValueTypeEnum.ARRAY) {
      return value
        .map(element => getLabelForValue(detail.options, element))
        .join(', ');
    }
  }

  return value;
};

Values.propTypes = {
  detail: DetailType.isRequired,
  value: DetailValueType,
};

export default Values;
