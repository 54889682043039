import {
  InnerContent,
  SimpleModal,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
  StatusLabel,
} from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import PartnerDetailsModal from 'components/PartnerDetailsModal';
import {
  makeSelectEnvVars,
  makeSelectImmediateHiringDecision,
} from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';
import useApplicantDetail from 'hooks/useApplicantDetail';
import InfoBlueIcon from 'images/info-blue.svg';

import useHandleHttpAction from './hooks';
import useStyles from './styles';
import { statusToStatusLabelProps } from './utils';

/**
 * @typedef {object} StatusLabelsProps
 * @property {Applicant} applicant
 */

/**
 * @param {StatusLabelsProps} props
 * @return {JSX.Element}
 */
function StatusLabels({ applicant, openStatusLabelMenu = () => {} }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles = useStyles();
  const { status: statuses, partner_label: partnerLabels } = applicant;
  const applicantId =
    applicant.id ||
    (applicant.status &&
      applicant.status[0] &&
      applicant.status[0].external_id);
  const intl = useIntl();
  const dispatch = useDispatch();
  const envVars = useSelector(makeSelectEnvVars());
  const { fetchData: fetchApplicantDetail } = useApplicantDetail(
    applicantId,
    false,
  );
  const handleHttpAction = useHandleHttpAction();
  const [partnerDetails, setPartnerDetails] = useState([]);
  const handleClosePartnerDetailsModal = () => {
    setPartnerDetails([]);
  };
  const immediateHiringDecision = useSelector(
    makeSelectImmediateHiringDecision(),
  );

  return (
    <Grid className={styles.statusLabels}>
      {[...statuses, ...(partnerLabels || [])].map(status => {
        let statusLabelProps;
        try {
          if (!status.status) {
            return null;
          }
          const handleOpenPartnerDetailsModal = details => {
            const filteredDetails = details.filter(
              detail => detail.partner?.name === status.type,
            );
            setPartnerDetails(filteredDetails);
          };
          statusLabelProps = statusToStatusLabelProps(
            status,
            {
              handleHttpAction,
              dispatch,
              envVars,
              fetchApplicantDetail,
              intl,
              handleOpenPartnerDetailsModal,
              setIsModalOpen,
            },
            immediateHiringDecision,
          );
          const foundDetails = status?.actions?.find(
            st => st.label === 'Partner Details',
          )?.partner_details;
          const hidePartnerDetailsAction = !foundDetails?.find(
            detail => detail.partner.name === status.type,
          );
          if (hidePartnerDetailsAction) {
            statusLabelProps.actions = statusLabelProps.actions.filter(
              prop => prop.label !== 'Partner Details',
            );
          }
        } catch (e) {
          dispatch(addMessageAction(e, 'error'));
          return null;
        }

        const modalAction = status.actions.find(({ type }) => type === 'modal');

        return (
          <Grid key={statusLabelProps.key} onClick={openStatusLabelMenu}>
            <StatusLabel {...statusLabelProps} />
            {partnerDetails.length > 0 && (
              <PartnerDetailsModal
                partnerDetails={partnerDetails}
                handleClose={handleClosePartnerDetailsModal}
                title={`${partnerDetails[0].partner?.name} Details`}
              />
            )}
            {isModalOpen && modalAction && (
              <SimpleModal
                fullScreenOnMobile
                onClose={() => setIsModalOpen(false)}
                maxWidth="90dvw"
                height="90dvh"
              >
                <SimpleModalHeader onClose={() => setIsModalOpen(false)}>
                  <SimpleModalHeaderIcon Icon={InfoBlueIcon} color="blue" />
                  <SimpleModalTitle>{modalAction.label}</SimpleModalTitle>
                </SimpleModalHeader>

                <InnerContent height="100%">
                  <iframe
                    style={{ height: '100%', width: '100%' }}
                    src={modalAction.url}
                    title="embed"
                    allow="camera; microphone; geolocation"
                  />
                </InnerContent>
              </SimpleModal>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

StatusLabels.propTypes = {
  applicant: PropTypes.object.isRequired,
  openStatusLabelMenu: PropTypes.func,
};

export default StatusLabels;
