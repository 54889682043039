export const STATUS_TYPES = {
  INCOMPLETE: 'incomplete',
  WAITING: 'waiting',
  IN_PROGRESS: 'in_progress',
  PENDING_ACTION: 'pending_action',
  COMPLETED: 'completed',
  ERROR: 'error',
  OPTED_OUT: 'opted_out',
  PARTNER: 'partner',

  // Additional status types
  WARNING: 'warning',
  ADVANCE: 'advance',
  REJECT: 'reject',
};

export const STATUS_LABEL_STATUS = {
  ACTION: 'action',
  INCOMPLETE: 'incomplete',
  IN_PROGRESS: 'in-progress',
  PARTNER: 'partner',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const LABEL_STATUSES = {
  'in-progress': STATUS_TYPES.IN_PROGRESS,
  incomplete: STATUS_TYPES.INCOMPLETE,
  'pending-action': STATUS_TYPES.PENDING_ACTION,
  complete: STATUS_TYPES.COMPLETED,

  // Scheduler
  'not-attended': STATUS_TYPES.WARNING,
  cancelled: STATUS_TYPES.ERROR,
  attended: STATUS_TYPES.COMPLETED,
  'missing-attendance': STATUS_TYPES.PENDING_ACTION,
  'not-scheduled': STATUS_TYPES.INCOMPLETE,
  scheduled: STATUS_TYPES.IN_PROGRESS,

  // BGC
  running: STATUS_TYPES.IN_PROGRESS,
  'pre-adverse': STATUS_TYPES.ERROR,
  'post-adverse': STATUS_TYPES.ERROR,
  suspended: STATUS_TYPES.ERROR,
  disputed: STATUS_TYPES.ERROR,
  fail: STATUS_TYPES.ERROR,
  unperformable: STATUS_TYPES.ERROR,
  withdrawn: STATUS_TYPES.ERROR,
  clear: STATUS_TYPES.COMPLETED,
  engaged: STATUS_TYPES.COMPLETED,
  pass: STATUS_TYPES.COMPLETED,
  consider: STATUS_TYPES.PENDING_ACTION,
  'not-consented': STATUS_TYPES.INCOMPLETE,
  consented: STATUS_TYPES.WAITING,
  adjudicate: STATUS_TYPES.PENDING_ACTION,
  paused: STATUS_TYPES.WAITING,
  'consider-engaged': STATUS_TYPES.COMPLETED,
  'meets-requirement': STATUS_TYPES.COMPLETED,
  'does-not-meet-requirement': STATUS_TYPES.ERROR,
  'needs-review': STATUS_TYPES.PENDING_ACTION,

  // Everify
  authorized: STATUS_TYPES.COMPLETED,
  closed: STATUS_TYPES.ERROR,
  'final-nonconfirmation': STATUS_TYPES.ERROR,
  'close-case-and-resubmit': STATUS_TYPES.ERROR,
  'confirm-details': STATUS_TYPES.PENDING_ACTION,
  'photo-match-requested': STATUS_TYPES.PENDING_ACTION,
  'pending-submission': STATUS_TYPES.PENDING_ACTION,
  'upload-documents': STATUS_TYPES.PENDING_ACTION,
  'tentative-nonconfirmation': STATUS_TYPES.PENDING_ACTION,
  referred: STATUS_TYPES.IN_PROGRESS,
  'manual-review': STATUS_TYPES.IN_PROGRESS,
  queued: STATUS_TYPES.IN_PROGRESS,

  // Document
  'pending-review': STATUS_TYPES.PENDING_ACTION,
  recollecting: STATUS_TYPES.IN_PROGRESS,
  approved: STATUS_TYPES.COMPLETED,
  waiting: STATUS_TYPES.WAITING,
  error: STATUS_TYPES.ERROR,
  'opted-out': STATUS_TYPES.OPTED_OUT,
  archived: STATUS_TYPES.COMPLETED,
  declined: STATUS_TYPES.ERROR,
  voided: STATUS_TYPES.ERROR,
  'i9-overdue': STATUS_TYPES.ERROR,
  'i9-expiring-soon': STATUS_TYPES.ERROR,

  // Tech Check
  advance: STATUS_TYPES.ADVANCE,
  reject: STATUS_TYPES.REJECT,

  // Partner
  partner: STATUS_TYPES.PARTNER,
};

export const MENU_OPTION_TYPES = {
  LINK: 'link',
  ACTION: 'action',
  RADIO: 'radio',
  HTTP: 'http',
  POPUP: 'popup',
  MODAL: 'modal',
};

export const OPEN_EMAIL_POPUP: Record<string, boolean> = {
  i9_redo_form: true,
  request_revision: true,
  i9_section_three_remote_form: true,
  w4_federal_redo_form: true,
  w4_state_redo_form: true,
};

export const OPEN_HELLOSIGN_IFRAME: Record<string, boolean> = {
  Hellosign: true,
  'White label Hellosign': true,
};

export const CATEGORY_SHOWS_STAGE_TYPE: Record<string, boolean> = {
  'Background Check': true,
};

export type StatusCode =
  | 'assessment'
  | 'data_collection'
  | 'file_review'
  | 'partner_label'
  | 'partner_status'
  | 'sessions'
  | 'tech_check'
  | 'video_recording'
  | 'Hellosign';
