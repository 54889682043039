import { Intercom, update } from '@intercom/messenger-js-sdk';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

function getQueryParam(search: string, paramName: string) {
  const urlParams = new URLSearchParams(search);
  return urlParams.get(paramName);
}

export const IntercomMessenger = () => {
  const location = useLocation();

  const persistedPopupType = useMemo(
    () => getQueryParam(location.search, 'persistedPopupType'),
    [location.search],
  );

  /* eslint-disable camelcase */
  const {
    account_name_v2: company,
    name,
    email,
    role,
    intercom_digest: user_hash,
    created_at: createdAtISO,
  } = useSelector(makeSelectWhoami());

  const created_at = createdAtISO ? Date.parse(createdAtISO) : null;
  const isIntercomInitialized = useRef(false);

  useEffect(() => {
    if (!created_at) return;

    if (!isIntercomInitialized.current) {
      Intercom({
        app_id: 'h9dh2723',
        user_hash,
        name,
        email,
        created_at,
        company,
        role,
        hide_default_launcher: persistedPopupType === 'applicantDrawerPopup',
      });
      isIntercomInitialized.current = true;
    } else {
      update({
        hide_default_launcher: persistedPopupType === 'applicantDrawerPopup',
      });
    }
  }, [persistedPopupType, created_at, user_hash, name, email, company, role]);
  /* eslint-enable camelcase */
  return null;
};
