const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
  },
  modalBg: {
    background: 'rgba(26, 51, 126, 0.65)',
  },
  padContent: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '&.noPadding': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&.fullHeight': {
      height: '100%',
    },
  },
  paperFullScreen: {
    background: theme.palette.background.default,
  },
  closeIcon: {
    position: 'absolute',
    right: 26.5,
    top: 26.5,
    cursor: 'pointer',
    color: theme.palette.text.hint,
  },
  closeIconMobile: {
    position: 'absolute',
    left: 26.5,
    top: 36.5,
    cursor: 'pointer',
    color: theme.palette.common.gray800,
  },
  topSection: {
    padding: theme.spacing(4, 4, 3, 4),
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    boxShadow: '0 1px 1px 0 rgba(164,168,179,0.16)',

    '&.reducedPadding': {
      padding: theme.spacing(1.5, 4),
    },

    '&.borderBottom': {
      borderBottom: '1px solid #E9EBF2',
    },
  },
  title: {
    color: theme.palette.common.gray800,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.common.gray600,
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
    whiteSpace: 'pre-line',
  },
  warningIcon: {
    fontSize: (theme.typography.fontSize * 12) / 14,
    marginRight: theme.spacing(0.5),
  },
  warning: {
    fontSize: (theme.typography.fontSize * 12) / 14,
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
  },
  content: {
    padding: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
    '&.noPadding': {
      padding: 0,
    },
    width: '100%',
  },
  triggerButton: {
    textTransform: 'none',
  },
  buttons: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    height: 48,
    fontSize: theme.typography.fontSize * 1.142,

    color: theme.palette.text.disabled,
    width: '100%',
    textTransform: 'none',
    boxShadow: '0 1px 1px 0 rgba(164,168,179,0.3)',
    borderRadius: 5,
  },
  buttonContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(1),
      '&:last-child': {
        paddingRight: 0,
      },
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  headerIconContainer: {
    marginBottom: theme.spacing(2.5),
  },

  // Mobile styles below
  paperMobile: {
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '&.noMargin': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&.fullHeight': {
      height: '100%',
    },
  },
  backButtonIcon: {
    position: 'absolute',
    top: theme.spacing(5),
    left: theme.spacing(3),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  headerRightElement: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(1),
    cursor: 'pointer',
  },
  padContentMobile: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      boxShadow: '0 1px 3px 0 rgba(54,56,64,0.1)',
      borderRadius: 5,
    },
    '&.noPadding': {
      padding: 0,
    },
    '&.noBoxShadow': {
      boxShadow: 'none',
    },
    '&.mobileDefaultBackground': {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(1),
      },
      background: theme.palette.background.default,
      boxShadow: 'none',
    },
    '&.extraMobilePaddingTop': {
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(3),
      },
    },
  },
  topSectionMobile: {
    padding: theme.spacing(4, 4, 3, 4),
    backgroundColor: theme.palette.background.default,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0 1px 1px 0 rgba(164,168,179,0.16)',
      backgroundColor: theme.palette.background.paper,
    },
    '&.noMargin': {
      backgroundColor: 'white',
    },
    '&.noPadding': {
      padding: 0,
    },
    '&.reducedPadding': {
      padding: theme.spacing(1.5, 4),
    },
    '&.borderBottom': {
      borderBottom: '1px solid #E9EBF2',
    },
  },
  titleMobile: {
    color: '#363840',
    fontSize: theme.typography.fontSize * 1.28,
    lineHeight: '22px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      color: '#363840',
      fontSize: (theme.typography.fontSize * 18) / 14,
      textAlign: 'center',
    },
  },
  buttonsMobile: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 0 3px 0 rgba(54,56,64,0.1)',
    },
  },
  buttonContainerMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(1),
      '&:last-child': {
        paddingLeft: theme.spacing(1),
        paddingRight: 0,
      },
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  fullHeight: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: '750px',
      backgroundColor: theme.palette.background.default,
    },
  },
});

export default styles;
