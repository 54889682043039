import {
  Button,
  SimpleModalHeader,
  SimpleModalHeaderIcon,
  SimpleModalTitle,
} from '@fountain/fountain-ui-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CustomModal from 'components/CustomModal';
import InfoBlueIcon from 'images/info-blue.svg';

import useStyles from './styles';

function PartnerDetail({ details, requestedRevision }) {
  const [isEmbedOpen, setIsEmbedOpen] = useState(false);
  const classes = useStyles();

  if (!details.length) return null;

  const partnerOption = details[0].partner_option;

  const partnerDetail = detail => {
    switch (detail.category) {
      case 'url':
      case 'file':
        return (
          <a href={detail.value} target="_blank" rel="noreferrer">
            {detail.label}
          </a>
        );
      case 'embed_url':
        return (
          <Grid container>
            <Button
              variant="contained"
              onClick={() => setIsEmbedOpen(true)}
              size="small"
              type="primary"
              style={{ width: 'auto' }}
            >
              {detail.label}
            </Button>
            <CustomModal
              isOpen={isEmbedOpen}
              handleClose={() => setIsEmbedOpen(false)}
              whiteBackground
              fullScreen
              noPadding
              fullHeight
              hideCloseButton
              classes={{
                root: classes.modalRoot,
                paperFullScreen: classes.modalContent,
                paper: classes.iframeModal,
                topSection: classes.topSection,
              }}
              customHeaderContent={
                <SimpleModalHeader onClose={() => setIsEmbedOpen(false)}>
                  <SimpleModalHeaderIcon Icon={InfoBlueIcon} color="blue" />
                  <SimpleModalTitle>{detail.label}</SimpleModalTitle>
                </SimpleModalHeader>
              }
            >
              <iframe
                className={classes.iframe}
                src={detail.value}
                title="embed"
                allow="camera; microphone; geolocation"
              />
            </CustomModal>
          </Grid>
        );
      default:
        return (
          <Grid container>
            <Typography variant="body2" className={classes.detailLabel}>
              {detail.label}:
            </Typography>
            <Typography variant="body2" className={classes.detailValue}>
              &nbsp;{detail.value}
            </Typography>
          </Grid>
        );
    }
  };

  return (
    <Grid className={classes.partnerDetailContainer}>
      <Typography
        variant="body1"
        className={classes.partnerOption}
        color="textPrimary"
      >
        {partnerOption} {requestedRevision && ' - Requested Revision'}
      </Typography>
      <Grid className={classes.partnerDetails}>
        {details.map(detail => (
          <Grid key={detail.key} className={classes.partnerDetail}>
            {partnerDetail(detail)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

PartnerDetail.propTypes = {
  details: PropTypes.array,
  requestedRevision: PropTypes.bool,
};

export default PartnerDetail;
