import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SharedConditionProps } from '../../types';
import { DataKeySelect } from '../components/DataKeySelect';
import { InputField } from '../InputField';
import { messages } from './messages';
import { ReasonSelect } from './ReasonSelect';

export const ApplicantDataDuplicate: FC<SharedConditionProps> = props => {
  const intl = useIntl();

  return (
    <>
      <DataKeySelect {...props} />
      <ReasonSelect {...props} />
      <Grid item xs={6}>
        <InputField
          {...props}
          placeholder={intl.formatMessage(messages.reapplyInterval)}
          required={false}
        />
      </Grid>
    </>
  );
};
