import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { Reason } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { defaultReason } from 'containers/WorkflowEditor/components/StageSettingMessageCard/components/MessageRow/MessageRow.fixtures';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { RulesProps, SharedConditionProps } from '../../../types';
import { messages } from '../messages';

export const ReasonSelect: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
}) => {
  const intl = useIntl();
  const { rejectedReasonsResult } = useContext(RulesEditDataContext);

  // Generating options based on type of stage and request response
  let reasons: Reason[] = [defaultReason];
  if (rejectedReasonsResult.status === 'ready') {
    reasons = reasons.concat(rejectedReasonsResult.data.rejection_reasons);
  }

  const onChangeReason = (reason: Reason) => {
    if (setRules) {
      setRules(
        produce((draftRules: RulesProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];

          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].extra.rejection_reason_id = reason.id;
        }),
      );
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          aria-label={intl.formatMessage(messages.reason)}
          label={intl.formatMessage(messages.reason)}
          options={reasons}
          value={
            reasons.find(option => {
              return option.id === condition.extra.rejection_reason_id;
            }) ?? null
          }
          getOptionLabel={(option: Reason) => {
            return option.content;
          }}
          getOptionValue={(option: Reason) => {
            return option.id;
          }}
          onChange={onChangeReason}
          placeholder={intl.formatMessage(messages.selectReason)}
        />
      </Grid>
    </>
  );
};
