import { defineMessages } from 'react-intl';
export const messages = defineMessages({
  reason: {
    defaultMessage: 'Reason',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.ApplicantDataDuplicate.reason`,
  },
  reapplyInterval: {
    defaultMessage: 'Number of days until applicant can reapply',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.ApplicantDataDuplicate.reapplyInterval`,
  },
  selectReason: {
    defaultMessage: 'Select a Reason',
    id: `app.containers.WorkflowEditor.components.Rules.Condition.ApplicantDataDuplicate.selectReason`,
  },
});
