/**
 *
 * CustomModal Component
 *
 */
import { theme as muiTheme } from '@fountain/fountain-ui-components';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PropTypes from 'prop-types';
import React from 'react';
import { Choose, classNames, If } from 'react-extras';
import { compose } from 'redux';

import CloseIcon from 'images/close-icon.svg';

import styles from './styles';

class CustomModal extends React.PureComponent {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleModalClose = event => {
    if (this.props.handleClose) {
      this.props.handleClose(event);
    } else {
      this.setState({ open: false });
    }
  };

  buttonColor = btn => {
    const btnTypes = {
      default: muiTheme.palette.background.paper,
      primary: muiTheme.palette.primary.main,
      warning: muiTheme.palette.error.main,
    };

    if (btn.color) {
      return btn.color;
    }
    if (btn.type) {
      return btnTypes[btn.type];
    }
    return muiTheme.palette.background.paper;
  };

  render() {
    const {
      classes,
      children,
      buttons,
      title,
      subtitle,
      warning,
      modalTriggerButton,
      modalTriggerText,
      modalTriggerButtonStyles,
      disableBackdropClick,
      headerIcon,
      leftIcon,
      fullScreen,
      supportMobile,
      noPadding,
      noBoxShadow,
      noMargin,
      mobileRightElement,
      mobileDefaultBackground,
      hideBackButton,
      customBodyClass,
      customFooterClass,
      customHeaderContent,
      extraMobilePaddingTop,
      whiteBackground,
      fullHeight,
      hideCloseButton,
      showCloseButtonInMobile,
      buttonProps,
      zIndexBoost,
      addHeaderBorderBottom,
      maxWidth,
    } = this.props;

    return (
      <Grid>
        {modalTriggerButton ? (
          <Button
            className={classes.triggerButton}
            style={modalTriggerButtonStyles}
            onClick={this.handleOpen}
          >
            {modalTriggerText || 'Select'}
          </Button>
        ) : null}
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleModalClose}
          disableAutoFocus
          className={classes.modalBg}
          disableBackdropClick={disableBackdropClick}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          classes={{
            root: fullScreen ? classes.root : '',
            paperFullScreen: classes.paperFullScreen,
          }}
          {...(zIndexBoost && {
            style: { zIndex: muiTheme.zIndex.customModal },
          })}
        >
          <Grid
            className={
              supportMobile
                ? classNames(classes.paperMobile, { noMargin, fullHeight })
                : classes.paper
            }
          >
            <If condition={supportMobile || !hideCloseButton}>
              <Hidden xsDown>
                <img
                  src={CloseIcon}
                  className={classes.closeIcon}
                  onClick={this.handleModalClose}
                  alt="close"
                  role="presentation"
                />
              </Hidden>
              <Hidden smUp>
                <If
                  condition={!hideBackButton}
                  render={() => (
                    <KeyboardBackspaceIcon
                      className={classes.backButtonIcon}
                      onClick={this.handleModalClose}
                    />
                  )}
                />
                <If
                  condition={hideBackButton && showCloseButtonInMobile}
                  render={() => (
                    <img
                      src={CloseIcon}
                      className={classes.closeIconMobile}
                      onClick={this.handleModalClose}
                      alt="close"
                      role="presentation"
                    />
                  )}
                />
                <If
                  condition={!!mobileRightElement}
                  render={() => (
                    <span className={classes.headerRightElement}>
                      {mobileRightElement}
                    </span>
                  )}
                />
              </Hidden>
            </If>
            <If condition={!supportMobile && !hideCloseButton}>
              <img
                src={CloseIcon}
                className={classes.closeIcon}
                onClick={this.handleModalClose}
                alt="close"
                role="presentation"
              />
            </If>
            <Grid
              className={
                supportMobile
                  ? classNames(classes.topSectionMobile, {
                      noMargin,
                      borderBottom: Boolean(addHeaderBorderBottom),
                      reducedPadding: Boolean(customHeaderContent),
                    })
                  : classNames(classes.topSection, {
                      reducedPadding: Boolean(customHeaderContent),
                      borderBottom: Boolean(addHeaderBorderBottom),
                    })
              }
            >
              <If condition={leftIcon !== undefined}>
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  className={classes.leftIconContainer}
                >
                  {leftIcon}
                </Grid>
              </If>
              <If condition={headerIcon !== undefined}>
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  className={classes.headerIconContainer}
                >
                  {headerIcon}
                </Grid>
              </If>
              <Choose>
                <Choose.When condition={customHeaderContent !== undefined}>
                  {customHeaderContent}
                </Choose.When>
                <Choose.Otherwise>
                  <Typography
                    variant="h3"
                    id="modal-title"
                    className={
                      supportMobile ? classes.titleMobile : classes.title
                    }
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h4"
                    id="simple-modal-description"
                    className={classes.subtitle}
                  >
                    {subtitle}
                  </Typography>
                  <If
                    condition={!!warning}
                    render={() => (
                      <Typography
                        color="error"
                        variant="h3"
                        className={classes.warning}
                      >
                        <ErrorIcon
                          className={classes.warningIcon}
                          fontSize="inherit"
                          color="error"
                        />
                        {warning}
                      </Typography>
                    )}
                  />
                </Choose.Otherwise>
              </Choose>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={
                supportMobile
                  ? classNames(classes.padContentMobile, customBodyClass, {
                      noPadding,
                      noBoxShadow,
                      mobileDefaultBackground,
                      extraMobilePaddingTop,
                    })
                  : classNames(classes.padContent, customBodyClass, {
                      noPadding,
                      whiteBackground,
                    })
              }
            >
              <Grid className={classNames(classes.content, { noPadding })}>
                {children}
              </Grid>
              <If
                condition={Boolean(buttons && buttons.length)}
                render={() => (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classNames(
                      supportMobile ? classes.buttonsMobile : classes.buttons,
                      customFooterClass,
                    )}
                    spacing={3}
                    justify="space-between"
                  >
                    {buttons.map(btn => (
                      <Grid
                        key={btn.name}
                        item
                        xs={supportMobile ? 12 / buttons.length : 12}
                        sm={12 / buttons.length}
                        className={classes.buttonContainer}
                      >
                        <Button
                          className={classes.button}
                          onClick={btn.onClick}
                          disabled={btn.disabled}
                          style={{
                            backgroundColor: this.buttonColor(btn),
                            color:
                              btn.color || btn.type
                                ? muiTheme.palette.background.paper
                                : muiTheme.palette.text.disabled,
                            opacity: btn.disabled ? 0.6 : 1,
                            cursor: btn.disabled ? 'not-allowed' : 'pointer',
                            pointerEvents: 'auto',
                            ...btn.styles,
                          }}
                          {...buttonProps}
                        >
                          {btn.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  extraMobilePaddingTop: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  warning: PropTypes.string,
  buttons: PropTypes.array,
  modalTriggerButton: PropTypes.bool,
  mobileDefaultBackground: PropTypes.bool,
  modalTriggerText: PropTypes.string,
  handleClose: PropTypes.func,
  modalTriggerButtonStyles: PropTypes.object,
  mobileRightElement: PropTypes.element,
  disableBackdropClick: PropTypes.bool,
  headerIcon: PropTypes.object,
  leftIcon: PropTypes.object,
  fullScreen: PropTypes.bool.isRequired,
  supportMobile: PropTypes.bool,
  supportMobileFullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  noMargin: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  customBodyClass: PropTypes.string,
  customFooterClass: PropTypes.string,
  customHeaderContent: PropTypes.object,
  buttonProps: PropTypes.object,
  whiteBackground: PropTypes.bool,
  fullHeight: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  showCloseButtonInMobile: PropTypes.bool,
  zIndexBoost: PropTypes.bool,
  addHeaderBorderBottom: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

CustomModal.defaultProps = {
  disableBackdropClick: false,
  mobileRightElement: null,
  children: [],
  buttons: [],
  noPadding: false,
  noBoxShadow: false,
  whiteBackground: false,
  hideCloseButton: false,
  showCloseButtonInMobile: false,
  zIndexBoost: false,
};

CustomModal.getDerivedStateFromProps = (props, state) => ({
  ...state,
  open: props.isOpen,
});

export default compose(
  withStyles(styles),
  withMobileDialog({ breakpoint: 'sm' }),
)(CustomModal);
